import React from 'react'
import { graphql, Link } from 'gatsby'
import { AdStarBlock, Category, GeneralSettings } from '../../types'
import { IMenu } from '../../graphql/entities/Menu'
import TopBanner from '../../components/Banners/TopBanner/TopBanner'
import Content from '../../components/Content/Content'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import AwardsHeader from '../../components/MuddyAwards/AwardsHeader'
import AwardsMenu from '../../components/MuddyAwards/AwardsMenu'
import Breadcrumb from '../../components/organisms/breadcrumb/Breadcrumb'
import PageMeta from '../../components/PageMeta/PageMeta'
import { AWARDS_COUNTY_INFO } from '../../lib/data/Awards'
import Leaderboard from '../../components/Banners/Leaderboard/Leaderboard'

export const query = graphql`
  query {
    wpgraphql {
      generalSettings {
        title
        siteId
        egSettingTwitter
        url
      }
      adStarCodes {
        id
        adslot
      }
      awardsMenu: menu(id: "Awards", idType: NAME) {
        menuItems {
          nodes {
            id
            path
            label
          }
        }
      }
      category(id: "muddy-awards", idType: SLUG) {
        sponsoredBy
        sponsoredLogo
      }
    }
  }
`

interface AwardsFAQProps {
  data: {
    wpgraphql: {
      generalSettings: GeneralSettings
      adStarCodes: AdStarBlock[]
      awardsMenu: {
        menuItems: {
          nodes: IMenu[]
        }
      }
      category: Category
    }
  }
}

const AwardsTandCs = ({
  data: {
    wpgraphql: {
      generalSettings,
      adStarCodes,
      awardsMenu: {
        menuItems: { nodes: subMenu }
      },
      category
    }
  }
}: AwardsFAQProps) => {
  const countyInfo = generalSettings.siteId
    ? AWARDS_COUNTY_INFO.filter(c => c.siteId === generalSettings.siteId)[0]
    : AWARDS_COUNTY_INFO[0]
  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: 'Muddy Stilettos Awards 2024',
          description:
            'The Muddy Stilettos Awards are the most coveted awards for independent lifestyle businesses across 28 counties, and totally FREE to enter!',
          image:
            'https://muddystilettos.co.uk/images/awards/Awards_Badge_2024.gif'
        }}
      />
      <Header />
      <TopBanner ads={adStarCodes} pageType={`awards`} />
      <Leaderboard adSlot={'TopLB'} />
      {category && (
        <AwardsHeader category={category} generalSettings={generalSettings} />
      )}
      {subMenu && (
        <AwardsMenu subMenu={subMenu} generalSettings={generalSettings} />
      )}
      <div>
        <Content>
          <Breadcrumb
            postType={`awards`}
            postTitle={'/ T&Cs'}
            postURI={'/awards/terms-and-conditions'}
          />
          <h1 dangerouslySetInnerHTML={{ __html: 'Terms & Conditions' }} />
          <p className="has-text-color" style={{ color: '#d41a50' }}>
            The Muddy Stilettos Awards 2024 Terms and Conditions
          </p>

          <ol>
            <li>
              Any business operating in the Awards categories and based in&nbsp;
              {countyInfo?.county ?? 'the UK'}&nbsp;can be nominated.
            </li>
            <li>
              Inclusion in the Awards is strictly at the discretion of the
              Awards organisers. We reserve the right to exclude any business or
              individual we do not believe to meet the eligibility requirements.
              No discussion will be entered into.
            </li>
            <li>
              All finalists are encouraged to display the Muddy Stilettos Awards
              logo on their website.{' '}
            </li>
            <li>
              You do not need to be subscribed to Muddy Stilettos to vote in the
              Awards. Any email address provided for the purposes of voting is
              not retained after the Awards have ended.
            </li>
            <li>
              By downloading their free promotion assets Nominees are agreeing
              to receive news about partnership opportunities from Muddy
              Stilettos. If you wish to opt out, please email{' '}
              <a href="mailto:awards@muddystilettos.co.uk">
                awards@muddystilettos.co.uk
              </a>
              .
            </li>
            <li>
              Nomination is online at{' '}
              <Link to="/awards/nominate/">
                https://
                {`${countyInfo?.siteSlug ? countyInfo.siteSlug + '.' : ''}`}
                muddystilettos.co.uk/awards/nominate/
              </Link>
              .
            </li>
            <li>
              Regional Finals Voting is online at{' '}
              <Link to="/awards/vote/">
                https://
                {`${countyInfo?.siteSlug ? countyInfo.siteSlug + '.' : ''}`}
                muddystilettos.co.uk/awards/vote/
              </Link>
            </li>
            <li>
              The Awards are only open to UK businesses and UK voting residents
            </li>
            <li>
              To nominate a UK based business, individuals must be a UK resident
              and sign-in with a valid email address to receive a unique link.
              Nominations must be validated on the website.
            </li>
            <li>
              To vote, individuals must be a UK resident and sign-in with a
              valid email address to receive a unique voting link to access the
              voting. Voting links must be validated on the website.
            </li>
            <li>
              Nomination and voting links will be invalided and will not operate
              if shared.
            </li>
            <li>
              Each individual may vote once per category, per email address.
            </li>
            <li>
              Muddy Stilettos reserves the right to cancel or suspend voting at
              any time.
            </li>
            <li>
              The eligibility of any vote is at the sole discretion of Muddy
              Stilettos.
            </li>
            <li>
              Muddy Stilettos reserves the right to disqualify entries or
              suspend voting if it has reasonable grounds to suspect that
              fraudulent voting has occurred or if it considers there has been
              any attempt to rig the voting.
            </li>
            <li>
              Any business found to be incentivising votes by offering prizes or
              discounts to their customers in return for votes may be
              disqualified.
            </li>
            <li>
              Any business or their associates found to be cheating or suspected
              of cheating by creating false email addresses leading to false
              votes, will have such votes removed and may be disqualified{' '}
            </li>
            <li>
              Muddy Stilettos, its sub-contractors, subsidiaries and/or agencies
              cannot accept any responsibility whatsoever for any technical
              failure or malfunction, or any other problem with the internet
              connectivity or line, system, server, provider or otherwise which
              may result in any vote being lost or not properly registered or
              recorded.
            </li>
            <li>
              Muddy Stilettos’ decision in relation to all matters affecting the
              Regional Vote is final.
            </li>
            <li>
              In the event of a dispute or tie, or if for any other reason Muddy
              Stilettos deems it necessary or appropriate, Muddy Stilettos
              reserves the right to make the final determination as to which of
              the nominees will be declared the Regional winner of the award.
            </li>
            <li>
              Every Regional Award winner will be considered for the National
              Muddy Stilettos Awards. The Muddy Stilettos Editors will form a
              judging panel and will select 5 National finalists per category.
              The finalists will be announced on 3 July 2024.
            </li>
            <li>
              In conjunction with Muddy Stilettos’ Editor-in-Chief Hero Brown,
              the judges will select the National winner for each category and
              Hero Brown will have the casting vote. Their decision will be
              final and no correspondence will be entered into. The National
              Muddy Stilettos Winners will be announced on 11 July 2024
            </li>
            <li>
              Any personal data collected will be used solely in connection with
              current UK data protection legislation. Please see Muddy
              Stilettos’ Privacy Policy for further details.
            </li>
            <li>
              Muddy Stilettos reserves the right to amend these Terms and
              Conditions without prior notice. Any changes will be posted on
              this website and such posting shall be adequate notice to all
              participants. Please check these Terms and Conditions each time
              you wish to vote.
            </li>
            <li>
              Should you wish to withdraw from the awards at any time please
              inform the awards organisers by emailing
              awards@muddystilettos.co.uk
            </li>
            <li>
              These Terms and Conditions shall be governed by English Law.
            </li>
          </ol>
        </Content>
      </div>
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default AwardsTandCs
